import { FC, ReactElement, ReactNode, ReactText } from 'react'

import {
  TextField,
  MenuItem,
  SelectProps as MuiSelectProps,
  TextFieldProps as MuiTextFieldProps,
  Box,
  ListItemText
} from '@mui/material'

import FormControl, { FormControlProps } from '~/components/base/FormControl'

import useFormField, { UseFormFieldProps } from '~/hooks/useFormField'
import useOptions, { OptionsProps } from '~/hooks/useOptions'

import ChevronDownSVG from '~/assets/icons/chevron-down.svg'

import Checkbox from '../base/Checkbox'

export type Option = {
  label: string
  value: string
  disabled?: boolean
}

interface CustomProps extends UseFormFieldProps {
  label?: string
  helperText?: ReactText | ReactElement | ReactNode | null
  options?: Option[]
  optionSettings?: Partial<OptionsProps>
  SelectProps?: Partial<MuiSelectProps>
  FormControlProps?: FormControlProps
}

export type SelectFieldProps = CustomProps & MuiTextFieldProps

const SelectField: FC<SelectFieldProps> = ({
  name,
  label,
  options: customOptions = [],
  optionSettings,
  SelectProps,
  helperText,
  FormControlProps,
  defaultValue: defaultSelectValue,
  transform,
  rules,
  ...rest
}) => {
  const { error, value, field } = useFormField({
    name,
    rules,
    transform,
    defaultValue: defaultSelectValue
  })

  const defaultValue = SelectProps?.multiple ? [] : ''

  const { options } = useOptions({
    multiple: SelectProps?.multiple,
    ...optionSettings,
    key: optionSettings?.key || name,
    options: customOptions
  })

  return (
    <FormControl
      helperText={helperText}
      errorMessage={error}
      {...FormControlProps}
    >
      <TextField
        {...field}
        select
        label={label}
        size="small"
        error={!!error}
        value={value || defaultValue}
        SelectProps={{
          ...SelectProps,
          MenuProps: {
            PaperProps: { sx: { maxHeight: 350, mt: 0.5 } }
          },
          renderValue: (selected: unknown) => {
            const selectValue = selected as string | string[]

            if (selectValue?.length === 0) {
              return <span>{rest.placeholder}</span>
            }

            if (SelectProps?.multiple) {
              return options
                .map((option: Option) => {
                  if (selectValue.includes(option.value)) {
                    return option.label
                  }

                  return ''
                })
                .filter(Boolean)
                .join(', ')
            }

            return options?.map((option: Option) => {
              if (selectValue.includes(option.value)) {
                return option.label
              }

              return ''
            })
          },

          IconComponent: (props) => {
            return (
              <Box
                {...props}
                width={16}
                height={16}
                mr={0.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ChevronDownSVG />
              </Box>
            )
          },
          onChange: field.onChange
        }}
        inputProps={{
          'data-testid': 'select-input',
          ...rest.inputProps
        }}
        {...rest}
        sx={{
          '& .MuiOutlinedInput-input.MuiSelect-select': {
            color: value ? 'text.secondary' : 'text.headline'
          }
        }}
      >
        {rest.placeholder && (
          <MenuItem disabled value="">
            {rest.placeholder}
          </MenuItem>
        )}
        {!!options?.length &&
          options.map(({ label, value: v, disabled }) => {
            return SelectProps?.multiple ? (
              <MenuItem
                dense
                key={v}
                value={v}
                sx={{
                  backgroundColor: '#fff',
                  '&.Mui-selected': {
                    background: '#fff'
                  }
                }}
              >
                <Checkbox size="medium" checked={value?.indexOf(v) > -1} />
                <ListItemText primary={label} />
              </MenuItem>
            ) : (
              <MenuItem disabled={disabled} dense key={v} value={v}>
                {label}
              </MenuItem>
            )
          })}
      </TextField>
    </FormControl>
  )
}

export default SelectField
