import React, { FC } from 'react'

import { Box, SxProps } from '@mui/material'

import plus from '~/assets/images/proffesional-account/plus.png'

interface StaticDatePickerTemplateProps {
  isCustomer?: boolean
  sxWrapper?: SxProps
}

const StaticDatePickerTemplate: FC<StaticDatePickerTemplateProps> = ({
  isCustomer,
  sxWrapper,
  children
}) => {
  return (
    <Box
      sx={{
        '& .css-1n2mv2k': {
          display: 'flex'
        },
        '& .MuiPaper-root': {
          padding: { xs: '5px 10px', md: '0 40px 10px' },

          '& > div > div:first-of-type': {
            width: { xs: 355, sm: 365 }
          },

          '& .MuiCalendarPicker-root > div:first-of-type': {
            position: 'relative',

            '&::before': {
              content: '""',
              position: 'absolute',
              right: 0,
              top: '34px',
              zIndex: 10,
              width: '100%',
              borderBottom: '1px solid',
              borderColor: 'stroke.main'
            }
          }
        },

        '& .MuiCalendarOrClockPicker-root > div:first-of-type': {
          width: { xs: 330, md: 365 }
        },

        '& .MuiPickerStaticWrapper-root > div > div, & .MuiCalendarPicker-root':
          { width: '100%', maxHeight: 370, maxWidth: 365 },

        '& .MuiPickersFadeTransitionGroup-root': {
          color: 'text.secondary',
          fontSize: '16px',
          lineHeight: '28px',
          fontWeight: 400
        },

        '& .MuiCalendarPicker-root > div:first-of-type': {
          padding: 0,
          marginBottom: '11px'
        },

        '& .css-34k09d': { width: 50 },

        '& .MuiPickerStaticWrapper-root': {
          position: 'relative',
          maxWidth: 365,
          '&::before': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: '55px',
            zIndex: 10,
            width: '100%',
            borderBottom: '1px solid',
            borderColor: 'stroke.main'
          },

          ...(isCustomer && {
            maxHeight: 355,
            mx: 'auto',
            backgroundColor: 'background.lightYellow'
          }),

          '& .MuiPickerStaticWrapper-content': {
            ...(isCustomer && {
              maxHeight: 355,
              mx: 'auto',
              backgroundColor: 'background.lightYellow'
            })
          }
        },

        // width form ManageCalendarForm
        '& .manageCalendar': {
          '& .MuiCalendarOrClockPicker-root > div:first-of-type': {
            width: { xs: 345, md: 365 }
          }
        },

        '& .MuiDayPicker-weekContainer .MuiPickersDay-root': {
          minWidth: 34
        },

        '& .MuiPickersDay-root': {
          width: 34,
          minWidth: 34,
          height: 34,
          margin: { xs: '3px 9px', md: '3px 10px' },
          fontSize: '18px'
        },

        '& .PrivatePickersSlideTransition-root': {
          minHeight: 220
        },

        '& .PrivatePickersYear-yearButton.Mui-disabled': {
          display: 'none'
        },

        '& .MuiButtonBase-root.Mui-disabled': {
          path: { fill: '#A4A8BB' }
        },

        ...(isCustomer && {
          '& .MuiPickersDay-root.Mui-disabled': {
            color: 'text.secondary',
            '&::before': {
              content: '""',
              position: 'absolute',
              display: 'block',
              width: 20,
              height: 20,
              zIndex: 23,
              backgroundImage: `url(${plus.src})`
            }
          }
        }),

        ...(isCustomer && {
          '& .MuiButtonBase-root.MuiPickersDay-root': {
            backgroundColor: 'background.lightYellow'
          },
          '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
            color: '#fff',
            backgroundColor: '#2C397C'
          },
          '& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover': {
            color: 'background.default'
          }
        }),

        '& .MuiTypography-caption': {
          fontSize: '18px',
          fontWeight: 600,
          color: 'text.primary',
          margin: { xs: '3px 19.5px', md: '3px 20.3px' }
        },

        ...sxWrapper
      }}
    >
      {children}
    </Box>
  )
}

export default StaticDatePickerTemplate
