import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useQuery, QueryOptions } from 'react-query'

import { formatOption } from '~/utils/helpers/form.helpers'
import { isOfType } from '~/utils/helpers/type.helpers'

export type OptionsProps = {
  key: any
  options?: any[]
  value?: any
  enabled?: boolean
  multiple?: boolean
  optionLabel?: string
  optionValue?: string
  caseSensitive?: boolean
  refetchOnChange?: any[]
  queryOptions?: QueryOptions
  loadOptions?: () => Promise<any>
  formatOptions?: (resp: any) => Promise<any> | any
  onRefetchSuccess?: () => void
}

const useOptions = ({
  options: customOptions = [],
  loadOptions,
  formatOptions,
  optionLabel = 'name',
  optionValue = 'id',
  value,
  key = null,
  caseSensitive,
  refetchOnChange = [],
  enabled,
  queryOptions,
  multiple,
  onRefetchSuccess
}: OptionsProps) => {
  const isMount = useRef(false)
  const {
    data: options,
    isLoading,
    refetch
  } = useQuery(key, loadOptions || (() => {}), {
    enabled: !!loadOptions && enabled,
    select: (data: any) => {
      let formattedOps = data.map((option) =>
        formatOption({
          option,
          optionLabel,
          optionValue
        })
      )

      if (typeof formatOptions === 'function') {
        formattedOps = formatOptions(formattedOps)
      }

      return formattedOps
    },
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    ...queryOptions
  })

  useEffect(() => {
    if (isMount.current) {
      refetch().then(() => onRefetchSuccess && onRefetchSuccess())
    }

    isMount.current = true
  }, [...refetchOnChange])

  const currentOptions = useMemo(() => {
    if (Array.isArray(customOptions) && !options?.length) {
      return customOptions.map((option) =>
        formatOption({
          option,
          optionLabel,
          optionValue
        })
      )
    }

    return options
  }, [customOptions, optionLabel, optionValue, options?.length])

  const currentValue = useMemo(() => {
    if (!value) return null

    const primitive = isOfType.string(value) || isOfType.number(value)
    const isOptions = !!currentOptions.length

    const getValueFromOptions = (currValue) => {
      const formatValue = (v) => {
        if (caseSensitive) {
          return String(v)
        }

        return String(v).toLowerCase()
      }

      return currentOptions.find(
        (option) =>
          formatValue(option.value) ===
          formatValue(currValue?.[optionValue] || currValue)
      )
    }

    if (primitive && isOptions) {
      return getValueFromOptions(value)
    }
    if (isOfType.object(value) && value?.[optionValue] && isOptions) {
      return getValueFromOptions(value)
    }
    if (isOfType.array(value) && isOptions) {
      return value
        .map((currValue) => getValueFromOptions(currValue))
        .filter(Boolean)
    }

    return value
  }, [value, optionValue, currentOptions])

  const getValue = useCallback(
    (v) =>
      multiple && Array.isArray(v)
        ? v.map((e) => e?.value)
        : v?.value || v || null,
    [multiple]
  )

  return {
    options: currentOptions,
    currentValue,
    loading: isLoading,
    refetch,
    getValue
  }
}

export default useOptions
