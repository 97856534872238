import { FC, ReactElement, ReactNode, ReactText } from 'react'

import { FormHelperText } from '@mui/material'
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps
} from '@mui/material/TextField'

import FormControl, { FormControlProps } from '~/components/base/FormControl'

import useFormField, { UseFormFieldProps } from '~/hooks/useFormField'

export interface TextFieldCustomProps extends UseFormFieldProps {
  helperText?: ReactText | ReactElement | ReactNode | null
  hideHelper?: boolean
  formControlProps?: FormControlProps
}

export type TextFieldProps = TextFieldCustomProps & MuiTextFieldProps

const TextField: FC<TextFieldProps> = ({
  name,
  id,
  transform,
  rules,
  defaultValue = '',
  helperText,
  hideHelper,
  label,
  formControlProps,
  ...rest
}) => {
  const { field, error, onChange, value } = useFormField({
    name,
    defaultValue,
    rules,
    transform
  })

  return (
    <FormControl errorMessage={error} {...formControlProps}>
      <MuiTextField
        {...field}
        error={!!error}
        label={label}
        size="small"
        onChange={onChange}
        value={value}
        {...rest}
      />

      {!error && helperText && (
        <FormHelperText
          sx={{
            m: 0,
            color: 'text.headline',
            fontSize: '12px'
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default TextField
