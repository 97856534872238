import { ChangeEvent, useCallback } from 'react'

import {
  useController,
  UseControllerProps,
  UseControllerReturn
} from 'react-hook-form'

import {
  getFormErrorByName,
  getTransformHandler,
  TransformSchema
} from '~/utils/helpers/form.helpers'

export interface UseFormFieldProps extends Omit<UseControllerProps, 'control'> {
  transform?: TransformSchema
}

export interface UseFormFieldReturn extends UseControllerReturn {
  error: string | null
  onChange: (...event: any[]) => void
  value: any
}

const useFormField = ({
  transform,
  name,
  defaultValue,
  rules,
  shouldUnregister
}: UseFormFieldProps) => {
  const { field, fieldState, formState } = useController({
    name,
    rules,
    defaultValue,
    shouldUnregister
  })

  const error = getFormErrorByName(formState.errors, name)
  const transformHandler = getTransformHandler(transform)

  const onChange = useCallback(
    (value: ChangeEvent<HTMLInputElement> | any) => {
      field.onChange(transformHandler.output(value))
    },
    [field.onChange]
  )

  const value = transformHandler.input(field.value)

  return { field, formState, fieldState, error, onChange, value }
}

export default useFormField
