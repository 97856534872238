import React, { FC, useState } from 'react'

import Close from '@mui/icons-material/Close'
import {
  IconButton,
  InputAdornment,
  Paper,
  PaperProps,
  PopperProps,
  TextField as TextInput,
  TextFieldProps,
  Typography
} from '@mui/material'
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers'

import useFormField, { UseFormFieldProps } from '~/hooks/useFormField'

import CalendarTodayIcon from '~/assets/icons/home/calendar-empty.svg'
import ArrowDownIcon from '~/assets/icons/proffesional-account/arrow-down.svg'
import ArrowLeftIcon from '~/assets/icons/proffesional-account/arrow-left.svg'
import ArrowRightIcon from '~/assets/icons/proffesional-account/arrow-right.svg'

import StaticDatePickerTemplate from './StaticDatePickerField/StaticDatePickerTemplate'

type PaperComponentProps = PaperProps & {
  setOpen: (open: boolean) => void
}

const PaperComponent: FC<PaperComponentProps> = React.forwardRef(
  ({ children, setOpen, title, ...props }, ref) => {
    return (
      <StaticDatePickerTemplate>
        <Paper
          ref={ref}
          {...props}
          style={{ position: 'relative', borderRadius: '8px' }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: 'absolute', top: 5, right: 10 }}
          >
            <Close sx={{ width: 20, height: 20 }} />
          </IconButton>

          <Typography variant="h5" fontWeight={600} pt={2.5}>
            {title}
          </Typography>

          {children}
        </Paper>
      </StaticDatePickerTemplate>
    )
  }
)
interface DatePickerFieldCustomProps extends UseFormFieldProps {
  id?: string
  required?: boolean
  placeholder?: string
  maxDate?: Date
  title?: string
  PopperProps?: Partial<PopperProps>
}

export type DatePickerFieldProps = DatePickerFieldCustomProps &
  TextFieldProps &
  Partial<DesktopDatePickerProps<any, any>>

const DatePickerField: FC<DatePickerFieldProps> = ({
  name,
  id,
  transform,
  maxDate,
  defaultValue = '',
  rules,
  label,
  required,
  title,
  PopperProps,
  disablePast,
  disableFuture,
  onMonthChange,
  shouldDisableDate,
  ...rest
}) => {
  const [open, setOpen] = useState(false)

  const { value, field, error, onChange } = useFormField({
    name,
    defaultValue,
    rules,
    transform
  })

  return (
    <DesktopDatePicker
      onMonthChange={onMonthChange}
      shouldDisableDate={shouldDisableDate}
      inputFormat="DD MMM YYYY"
      label={label}
      value={value}
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      onChange={onChange}
      maxDate={maxDate}
      disablePast={disablePast}
      disableFuture={disableFuture}
      components={{
        RightArrowIcon: ArrowRightIcon,
        LeftArrowIcon: ArrowLeftIcon,
        SwitchViewIcon: ArrowDownIcon
      }}
      PaperProps={{
        title,
        // @ts-ignore
        setOpen,
        component: PaperComponent
      }}
      PopperProps={PopperProps}
      renderInput={({ inputProps, ...props }) => {
        return (
          <TextInput
            {...props}
            id={id}
            onClick={() => setOpen(true)}
            variant="outlined"
            error={!!error}
            helperText={error}
            fullWidth
            data-testid="date-picker"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  onClick={() => setOpen(true)}
                  position="start"
                  disablePointerEvents
                >
                  <CalendarTodayIcon />
                </InputAdornment>
              )
            }}
            inputProps={{
              ...inputProps,
              placeholder: rest.placeholder
            }}
            {...rest}
            {...field}
          />
        )
      }}
    />
  )
}

export default React.memo(DatePickerField)
